
.multi-range__slider {
	position: relative;
	width: 100%;
	max-width: 200px;
	margin-top: 20px;
    margin-bottom: 10px;
    .slider {
        position: relative;
        z-index: 1;
        height: 5px;
    }
    .slider > .track {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: color(gray-200);
    }
    .slider > .range {
        position: absolute;
        z-index: 2;
        left: 25%;
        right: 25%;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: color(primary);
    }
    .slider > .thumb {
        position: absolute;
        z-index: 3;
        width: 10px;
        height: 10px;
        background-color: color(white);
        border-radius: 50%;
        @include shadow-1;
        transition: box-shadow .3s ease-in-out;
    }
    .slider > .thumb.left {
        left: 25%;
        transform: translate(-5px, -3px);
    }
    .slider > .thumb.right {
        right: 25%;
        transform: translate(5px, -3px);
    }
    .slider > .thumb.hover {
        box-shadow: 0 0 0 5px rgba(63, 63, 63, 0.186);
    }
    .slider > .thumb.active {
        box-shadow: 0 0 0 10px rgba(63, 63, 63, 0.386);
    }
    .slider-value{
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 13px;
    }
    input[type=range] {
        position: absolute;
        pointer-events: none;
        -webkit-appearance: none;
        z-index: 2;
        height: 10px;
        width: 100%;
        opacity: 0;
    }
    input[type=range]::-webkit-slider-thumb {
        pointer-events: all;
        width: 30px;
        height: 30px;
        border-radius: 0;
        border: 0 none;
        background-color: red;
        -webkit-appearance: none;
    }
}


