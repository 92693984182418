@mixin d-flex {
    display: flex;
}
@mixin d-flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin shadow-1 {
    box-shadow: 0px 0px 4px color(gray-400);
}
@mixin shadow-2 {
    box-shadow: 0px 0px 10px #c3c3c3;
}
@mixin scroll-1 {
    &::-webkit-scrollbar {
        width: 10px;
        
    }
      
    &::-webkit-scrollbar-track {
        background: color(gray-300); 
    }
       
    &::-webkit-scrollbar-thumb {
        background: color(gray-500);  
    }
}
@mixin scroll-2 {
    &::-webkit-scrollbar {
        width: 10px;
        
    }
      
    &::-webkit-scrollbar-track {
        background: color(gray-300); 
    }
       
    &::-webkit-scrollbar-thumb {
        background: color(primary);  
    }
}
@mixin scroll-3 {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: color(invisible); 
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: color(gray-300);  
    }
}
@mixin text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}