.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: color(white);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 14px;
    color: #595959;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: color(gray-200);
  }
  
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    font-size: 24px;
    cursor: pointer;
    color: #ffffff;
    background: #0000;
    border: none;
    margin-right: 11px;
    border: 1px solid #ffffff1c;
    border-radius: 4px;
    padding: 0px 5px;
  }
  
  .openbtn:hover {
    background-color: color(gray-100);
    color: #000;
  }
  
  #main {
    padding: 16px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }



  .filter-sidebar{
    min-width: 250px;
    background: color(gray-100);
    padding: 15px;
    .nav-link{
      color: color(medium) !important;
    }
    a{
      font-size: 13px;
    }
    .form-control{
      width: 75px !important;
      height: 27px;
      font-size: 12px;
      padding: 4px;
    }
  }

  .user-sidebar{
    min-width:284px;
    background: #ECF1F5;
    padding: 15px !important;
    padding-bottom: 50px !important;
    font-size: 15px;
    .nav-link{
      color: color(medium) ;
    }
    .form-control{
      width: 75px !important;
      height: 27px;
      font-size: 12px;
      padding: 4px;
    }
  }

  .parent-category{
    display: flex;
    flex-wrap: wrap;
  }
  .sub-category{
    width: calc(100% - 7.5px);
    margin-left: 7.5px;
    height: auto;
    position: relative;
    &:before{
      content:"";
      position:absolute;
      left: 0;
      top: 0;
      height: calc(100% - 17px);
      width: 1px;
      border-left:1px solid #959595;
    }
    &__item{
    margin-left:15px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      left: -15px;
      bottom: 17px;
      height: 1px;
      width: 18px;
      background: #959595;
    }
      &--head{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &::before{
          content:'';
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          width: 15px;
          height: 0.5px;
          border-top:1px solid #959595;
        }
      }
    }
  }


  .uk-accordion-title.filter-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before{
      content: inherit;
    }
  }