.quantity-conter{
    width: 90px;
    @include d-flex;
    justify-content: space-between;
    align-items: center;
    button{
        @include flex-center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: color(white);
        border: 1px solid color(gray-300);
        font-size: 22px;
    }
    input{
        width: 25px;
        height: 25px;
        border: 0px;
        outline: none !important;
        padding: 5px;
        text-align: center;
        background: color(white);
        border: 1px solid color(gray-300);
    }
}