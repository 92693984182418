.addimg-block{
    &__block{
        position: relative;
        background: color(gray-100);
        width: 50px;
        height: 50px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid color(gray-200);
        @include flex-center;
        margin-right: 10px;
        img{
            position: absolute;
            width: 98%;
            height: 98%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            pointer-events: none;
            cursor: pointer;
        }
        input[type="file"]{
            position: absolute;
            width: 98%;
            height: 98%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            opacity: 0;
        }
        i{

        }
    }
}