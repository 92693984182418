.top-header{
    padding: 5px 0px;
    background: #0A4A77;
    color: #fff;
    label{
        display: flex;
        align-items: center;
        font-size: 12px !important;
    }
    .right-topbar-content{
        width: 300px;
        justify-content: end;
    }
}
.header{
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 0px;
    min-height: 75px;
    @media(max-width:768px){
        height: auto;
    }
    &__left-control{
        max-width: 950px;
        width: 100%;
        @media(max-width:1280px){
            width: 855px;
        }
        @media(max-width:1180px){
            width: 555px;
        }
        @media(max-width:992px){
            width: fit-content;
        }
    }
    &__right-control{
        width: 270px;
        .btn.dropdown-toggle{
            padding: 0;
            &::after{
                content: none;
            }
        }
    }
    &__logo{
        width: 200px;
        @media(max-width:760px) {
            width: 150px;
        }
    }
    &__location{
        img{
            width:24px
        }
        label{
            display: block;
            width: 100%;
            font-size: 13px;
        }
    }
}

.user-sec{
    min-width: 180px;
    img{
        width: 30px;
        height: 30px;
        border-radius: 0.25rem;
    }
}

.top-menu{
    background: color(white);
    height: 45px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid #094a7657;
    .nav-link{
        color: color(dark) !important;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}

.cart-count{
    position: absolute;
    top: -2px;
    left: 10px;
    width: 18px;
    height: 18px;
    background: color(primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: color(dark);
    font-size: 10px;
    font-weight: 600;
}
.category-onhover{
    position: absolute;
    top: 114px;
    width: 100%;
    background: #fff;
    height: fit-content;
    max-height: calc(100vh - 180px);
    overflow-y: scroll;
    z-index: 10;
    .nav-link{
        color: color(dark);
        transition: 300ms;
        &:hover{
            font-weight: 500;
            color: color(primary) !important;
            text-decoration: none !important;
        }
    }
    img{
        object-fit: cover;
        max-height: 300px;
        height: 100%;
        object-position: top left;
    }
    .menu-column{
        max-height: calc(100vh - 220px);
        overflow-y: scroll;
        @include scroll-3;
    }
}
.flag {
    width: 24px;
}

.category-menu-item{
    .nav-link{
        font-weight:500;
        text-decoration:none;
        &:hover{
            color:color(primarytwo) !important;
        }
    }
}