.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    border: 6px solid #086DB5;
    border-color: #086DB5 transparent #086DB5 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .toaster{
    color: white;
    font-weight: bold;
    font-size: 14px;
  }