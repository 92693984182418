@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.material-icons {
    font-family: 'Material Icons' !important;
}
.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
}
.material-icons-two-tone {
    font-family: 'Material Icons Two Tone' !important;
}
.material-icons-round {
    font-family: 'Material Icons Round' !important;
}
.material-icons-sharp {
    font-family: 'Material Icons Sharp' !important;
}


.title-font{
    font-size: 22px;
    @media(max-width:768px){
        font-size: 18px;
    }
}